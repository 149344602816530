<template>
  <div v-show="!isEmpty" class="seachResults-inner-bg py-3">
    <div class="container inner-container">
      <h1 h1 class="dynamic-sub-title-search">{{ $t('page.blog') }}</h1>
      <p v-if="isEmpty" class="text-center pa-15 searchEmpty">{{ $t("search.not.found", {name: query}) }}</p>
      <div v-else>
        <v-row class="my-md-8 my-3">
          <v-col
            v-for="item in list"
            :key="item.id"
            cols="12"
            lg="3"
            md="6"
            sm="6"
          >
            <Item :item="item" type="blog" />
          </v-col>
        </v-row>
        <div v-if="has_next" class="d-flex justify-center mb-5">
          <Main-btn @click="more">{{ $t('action.look-more') }}</Main-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import indexList from "@/components/page/indexList.js";
export default {
  mixins: [indexList],
  props: {
    query: String,
  },
  components: {
    Item: () => import("../item/item.vue"),
  },
  computed: {
    indexApi() {
      return this.$api.blog.public.index;
    },
    otherParams() {
      return {
        per: 4,
        search: this.query,
      };
    },
  },
};
</script>